<template lang="pug">
    .assembly-process-page
        .banner.d-flex.justify-center.align-center(:style="{ backgroundImage: `url(${assemblyProcessBanner})` }")
            h1.text-h3.white--text.orange--text.text--darken-3.font-weight-bold CORE SERVICES
        v-container
            section.py-10
                //- h3.text-center.text-h5.font-weight-bold.light-blue--text.text--accent-3.mb-4 Core Services
                p.main-title Comprehensive marine technical stores comprising of a wide array selection for various departments on vessels:

                v-row.mt-5
                    v-col.text-center(:cols="3") Deck Stores
                    v-col.text-center(:cols="3") Engineer Stores
                    v-col.text-center(:cols="3") Cabin stores
                    v-col.text-center(:cols="3") Safety Equipment
                v-row
                    v-col.text-right(:cols="4") Medication
                    v-col.text-center(:cols="4") Stationery
                    v-col.text-left(:cols="4") Navigation
                v-row.mb-5
                    v-col.text-center(:cols="3")
                    v-col.text-center(:cols="3") Provision
                    v-col.text-center(:cols="3") Bonded Stores
                    v-col.text-center(:cols="3")
                v-row.mb-5
                    v-col(
                        v-for="assemblyProcess in assemblyProcessList"
                        :key="assemblyProcess.title"
                        :cols="12"
                        :sm="3"
                    )
                        v-hover(v-slot="{ hover }")
                            v-card(flat)
                                v-img(
                                    height="200px"
                                    :src="assemblyProcess.imgUrl"
                                    :lazySrc="assemblyProcess.imgUrl"
                                )
                                //- v-card-text.text-center.text-body-1.font-weight-bold {{ assemblyProcess.title }}
                ul
                    li
                        p.description Our goal is to deliver the purpose fit quality stores and provision guided by compliance to the rules and regulations in the marine marketplace and that specified by our Customers.
                    li
                        p.description Brisk handles the coordination and logistics of ship stores to our in-house warehouses for safekeeping and consolidation for onwards timely deliveries according to our Customers vessels' schedules.
                    li
                        p.description Equipment offland services for on shore repairs or transits are provided to complement our Customer's operational needs.
                    li
                        p.description Garbage, scrap, slop & sludge disposal services are available on request. Lubricant, bunker, potable water supplies are available on referral basis.
</template>

<script>
import assemblyProcessBanner from "@/assets/assemblyProcessBanner.jpg";
// import assembly1 from "@/assets/assemblyProcess/assembly-1.jpg";
// import assembly2 from "@/assets/assemblyProcess/assembly-2.jpg";
// import assembly3 from "@/assets/assemblyProcess/assembly-3.jpg";
// import assembly4 from "@/assets/assemblyProcess/assembly-4.jpg";
// import assembly5 from "@/assets/assemblyProcess/assembly-5.jpg";
// import assembly6 from "@/assets/assemblyProcess/assembly-6.jpg";

import assembly1 from "@/assets/brisk/assembly-1.jpg";
import assembly2 from "@/assets/brisk/assembly-2.jpg";
import assembly4 from "@/assets/brisk/assembly-4.jpg";
import assembly5 from "@/assets/brisk/assembly-5.jpg";
import assembly6 from "@/assets/brisk/assembly-6.jpg";
import assembly7 from "@/assets/brisk/assembly-7.jpg";
import assembly8 from "@/assets/brisk/assembly-8.jpg";
import assembly9 from "@/assets/brisk/assembly-9.jpg";

export default {
    data: () => ({
        assemblyProcessBanner,
    }),
    computed: {
        assemblyProcessList: {
            get: () => [
                {
                    title: "Deck Stores",
                    imgUrl: assembly1,
                },
                {
                    title: "Engineer Stores",
                    imgUrl: assembly2,
                },
                {
                    title: "Cabin stores",
                    imgUrl: assembly9,
                },
                {
                    title: "Safety Equipment",
                    imgUrl: assembly4,
                },
                {
                    title: "Medication",
                    imgUrl: assembly5,
                },
                {
                    title: "Stationery",
                    imgUrl: assembly6,
                },
                {
                    title: "Navigation",
                    imgUrl: assembly7,
                },
                {
                    title: "Provision",
                    imgUrl: assembly8,
                }
            ],
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/styles/mediaQuery.scss';

.main-title {
    font-size: 2rem;
    font-weight: 500;
    margin: 0 30px 40px;
    color: #1e1e1e;
}
.description {
    font-size: 24px;
    font-weight: 500;
    color: #555;
    line-height: 40px;
}

@include MQ(mobile) {
    .main-title {
        font-size: 1.4rem;
        margin: 0 0 20px 0;
    }
    .description {
        font-size: 1.2rem;
    }
}
</style>
