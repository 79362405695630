import { render, staticRenderFns } from "./CoreServices.vue?vue&type=template&id=e033c7d6&scoped=true&lang=pug&"
import script from "./CoreServices.vue?vue&type=script&lang=js&"
export * from "./CoreServices.vue?vue&type=script&lang=js&"
import style0 from "./CoreServices.vue?vue&type=style&index=0&id=e033c7d6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e033c7d6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCol,VContainer,VHover,VImg,VRow})
